import React from "react";
import { Button, Col, Row } from "reactstrap";
import { useWeb3React } from "@web3-react/core";
import { injected } from "./utils";

const Connect = () => {
  const { activate } = useWeb3React();
  const connect = async (e) => {
    e.preventDefault();
    await activate(injected);
  }
  return (
    <>
      <Row className={'mt-5'}>
        <Col className={'text-center'}>
          <Button onClick={(e)=> connect(e)}>Wallet connect</Button>
        </Col>
      </Row>
    </>
  )
}

export default Connect;