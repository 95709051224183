import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from "reactstrap";
import { useWeb3React } from "@web3-react/core";
import Connect from "./Connect";
import Refresh from "./Refresh";
import Web3 from "web3";
import BigNumber from "bignumber.js";
import {
  ABI_STAKE,
  CONTRACT_STAKE,
  ABI_TOKEN,
  CONTRACT_TOKEN,
  ABI_TRADE,
  CONTRACT_TRADE,
  ZERO_ADDRESS
} from "./utils";

export const convertToVisual = (value, decimals) => {
  const multiplier = new BigNumber(10).pow(decimals);
  const result = new BigNumber(value).dividedBy(multiplier);
  return result.toFixed();
}

export const convertToReal = (value, decimals) => {
  const multiplier = new BigNumber(10).pow(decimals);
  const result = new BigNumber(value).multipliedBy(multiplier);
  return result.toFixed();
}

const AdminPage = () => {
  const { active, account, library } = useWeb3React();
  const [hashUserInfo, setHashUserInfo] = useState();
  const [hashInfo, setHashInfo] = useState();
  const [access, setAccess] = useState(false);
  const [isRun, setIsRun] = useState(false);
  const [isRunExtra, setIsRunExtra] = useState(false);
  const [fee, setFee] = useState(0);
  const [slippage, setSlippage] = useState(0);
  const [slippageExtra, setSlippageExtra] = useState(0);
  const [minStakeAmount, setMinStakeAmount] = useState(0);
  const [maxStakeAmount, setMaxStakeAmount] = useState(0);
  const [token2CoinSwapPercent, setToken2CoinSwapPercent] = useState(0);
  const [swapPercentExtra, setSwapPercentExtra] = useState(0);
  const [closeStakePenaltyPercent, setCloseStakePenaltyPercent] = useState(0);
  const [maxPriceUSDTExtra, setMaxPriceUSDTExtra] = useState(0);
  const [totalNowStake, setTotalNowStake] = useState(0);
  const [usersCount, setUsersCount] = useState(0);
  const [totalReward, setTotalReward] = useState(0);
  const [referralTotalReward, setReferralTotalReward] = useState(0);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [coinBalance, setCoinBalance] = useState(0);
  const [withdrawTokensAmount, setWithdrawTokensAmount] = useState(0);
  const [withdrawCoinsAmount, setWithdrawCoinsAmount] = useState(0);
  const [withdrawTokensAmountExtra, setWithdrawTokensAmountExtra] = useState(0);
  const [withdrawCoinsAmountExtra, setWithdrawCoinsAmountExtra] = useState(0);
  const [userAddress, setUserAddress] = useState("");
  const [userBlackList, setUserBlackList] = useState(false);
  const [referrer, setReferrer] = useState("");
  const [userTokenBalance, setUserTokenBalance] = useState(0);
  const [userCoinBalance, setUserCoinBalance] = useState(0);
  const [userData, setUserData] = useState({
    amount: 0,
    dayPeriod: 0,
    percent: 0,
    claimHourPeriod: 0,
    claimPercent: 0,
    reward: 0,
    totalReward: 0,
    startTime: 0,
    updateTime: 0,
  })

  const loadInfo = async () => {
    try {
      if (!library) return;
      const web3 = new Web3(library);
      const stakeContract = new web3.eth.Contract(ABI_STAKE, CONTRACT_STAKE);
      const tradeContract = new web3.eth.Contract(ABI_TRADE, CONTRACT_TRADE);
      const tokenContract = new web3.eth.Contract(ABI_TOKEN, CONTRACT_TOKEN);

      stakeContract.methods.isRun().call()
        .then(res=> {
          setIsRun(res);
        })
        .catch(err=> {
          console.log("isRun method error:", err);
          setIsRun(false);
        });

      tradeContract.methods.isRun().call()
        .then(res=> {
          setIsRunExtra(res);
        })
        .catch(err=> {
          console.log("isRun extra method error:", err);
          setIsRunExtra(false);
        });

      stakeContract.methods.fee().call()
        .then(res=> {
          setFee(convertToVisual(res, 18));
        })
        .catch(err=> {
          console.log("fee method error:", err);
          setFee(0);
        });

      stakeContract.methods.slippage().call()
        .then(res=> {
          setSlippage(convertToVisual(res, 2));
        })
        .catch(err=> {
          console.log("slippage method error:", err);
          setSlippage(0);
        });

      tradeContract.methods.slippage().call()
        .then(res=> {
          setSlippageExtra(convertToVisual(res, 4));
        })
        .catch(err=> {
          console.log("slippage extra method error:", err);
          setSlippageExtra(0);
        });

      stakeContract.methods.minStakeAmount().call()
        .then(res=> {
          setMinStakeAmount(convertToVisual(res, 18));
        })
        .catch(err=> {
          console.log("minStakeAmount method error:", err);
          setMinStakeAmount(0);
        });

      stakeContract.methods.maxStakeAmount().call()
        .then(res=> {
          setMaxStakeAmount(convertToVisual(res, 18));
        })
        .catch(err=> {
          console.log("maxStakeAmount method error:", err);
          setMaxStakeAmount(0);
        });

      stakeContract.methods.token2CoinSwapPercent().call()
        .then(res=> {
          setToken2CoinSwapPercent(convertToVisual(res, 4));
        })
        .catch(err=> {
          console.log("token2CoinSwapPercent method error:", err);
          setToken2CoinSwapPercent(0);
        });

      tradeContract.methods.swapPercent().call()
        .then(res=> {
          setSwapPercentExtra(convertToVisual(res, 4));
        })
        .catch(err=> {
          console.log("swapPercent extra method error:", err);
          setSwapPercentExtra(0);
        });

      stakeContract.methods.closeStakePenaltyPercent().call()
        .then(res=> {
          setCloseStakePenaltyPercent(convertToVisual(res, 4));
        })
        .catch(err=> {
          console.log("closeStakePenaltyPercent method error:", err);
          setCloseStakePenaltyPercent(0);
        });

      tradeContract.methods.maxPriceUSDT().call()
        .then(res=> {
          setMaxPriceUSDTExtra(convertToVisual(res, 18));
        })
        .catch(err=> {
          console.log("maxPriceUSDT extra method error:", err);
          setMaxPriceUSDTExtra(0);
        });

      stakeContract.methods.totalNowStake().call()
        .then(res=> {
          setTotalNowStake(convertToVisual(res, 18));
        })
        .catch(err=> {
          console.log("totalNowStake method error:", err);
          setTotalNowStake(0);
        });

      stakeContract.methods.getUsersCount().call()
        .then(res=> {
          setUsersCount(convertToVisual(res,0));
        })
        .catch(err=> {
          console.log("getUsersCount method error:", err);
          setUsersCount(0);
        });

      stakeContract.methods.totalReward().call()
        .then(res=> {
          setTotalReward(convertToVisual(res, 18));
        })
        .catch(err=> {
          console.log("totalReward method error:", err);
          setTotalReward(0);
        });

      stakeContract.methods.referralTotalReward().call()
        .then(res=> {
          setReferralTotalReward(convertToVisual(res, 18));
        })
        .catch(err=> {
          console.log("referralTotalReward method error:", err);
          setReferralTotalReward(0);
        });

      tokenContract.methods.balanceOf(CONTRACT_STAKE).call()
        .then(res=> {
          setTokenBalance(convertToVisual(res, 18));
        })
        .catch(err=> {
          console.log("balanceOf method error:", err);
          setTokenBalance(0);
        });

      web3.eth.getBalance(CONTRACT_STAKE)
        .then(res=> {
          setCoinBalance(convertToVisual(res, 18));
        })
        .catch(err=> {
          console.log("getBalance error:", err);
          setCoinBalance(0);
        });
    } catch (e) {
      console.log("load info error:", e);
    }
  }

  const updateIsRun = async (e) => {
    e.preventDefault();
    try {
      if (!library) return;
      const web3 = new Web3(library);
      const contract = new web3.eth.Contract(ABI_STAKE, CONTRACT_STAKE);
      await contract.methods
        .updateIsRun(!isRun)
        .send({from: account})
        .then((res)=> {
          setIsRun(!isRun);
          setHashInfo(res.transactionHash);
          alert('Done');
        }).catch(err=> {
          console.log("updateIsRun method error:", err);
          alert(err);
        });
    } catch (e) {
      console.log("update isRun error:", e);
    }
  }

  const updateIsRunExtra = async (e) => {
    e.preventDefault();
    try {
      if (!library) return;
      const web3 = new Web3(library);
      const contract = new web3.eth.Contract(ABI_TRADE, CONTRACT_TRADE);
      await contract.methods
        .updateIsRun(!isRunExtra)
        .send({from: account})
        .then((res)=> {
          setIsRunExtra(!isRunExtra);
          setHashInfo(res.transactionHash);
          alert('Done');
        }).catch(err=> {
          console.log("updateIsRunExtra method error:", err);
          alert(err);
        });
    } catch (e) {
      console.log("update isRun extra error:", e);
    }
  }

  const updateFee = async (e) => {
    e.preventDefault();
    try {
      if (!library) return;
      const web3 = new Web3(library);
      const contract = new web3.eth.Contract(ABI_STAKE, CONTRACT_STAKE);
      await contract.methods
        .updateFee(convertToReal(fee, 18))
        .send({from: account})
        .then((res)=> {
          setHashInfo(res.transactionHash);
          alert('Done');
        }).catch(err=> {
          console.log("updateFee method error:", err);
          alert(err);
        });
    } catch (e) {
      console.log("update fee error:", e);
    }
  }

  const updateSlippage = async (e) => {
    e.preventDefault();
    try {
      if (!library) return;
      const web3 = new Web3(library);
      const contract = new web3.eth.Contract(ABI_STAKE, CONTRACT_STAKE);
      await contract.methods
        .updateSlippage(convertToReal(slippage, 2))
        .send({from: account})
        .then((res)=> {
          setHashInfo(res.transactionHash);
          alert('Done');
        }).catch(err=> {
          console.log("updateSlippage method error:", err);
          alert(err);
        });
    } catch (e) {
      console.log("update slippage error:", e);
    }
  }

  const updateSlippageExtra = async (e) => {
    e.preventDefault();
    try {
      if (!library) return;
      const web3 = new Web3(library);
      const contract = new web3.eth.Contract(ABI_TRADE, CONTRACT_TRADE);
      await contract.methods
        .updateSlippage(convertToReal(slippageExtra, 4))
        .send({from: account})
        .then((res)=> {
          setHashInfo(res.transactionHash);
          alert('Done');
        }).catch(err=> {
          console.log("updateSlippageExtra method error:", err);
          alert(err);
        });
    } catch (e) {
      console.log("update slippage extra error:", e);
    }
  }

  const updateMinStakeAmount = async (e) => {
    e.preventDefault();
    try {
      if (!library) return;
      const web3 = new Web3(library);
      const contract = new web3.eth.Contract(ABI_STAKE, CONTRACT_STAKE);
      await contract.methods
        .updateMinStakeAmount(convertToReal(minStakeAmount, 18))
        .send({from: account})
        .then((res)=> {
          setHashInfo(res.transactionHash);
          alert('Done');
        }).catch(err=> {
          console.log("updateMinStakeAmount method error:", err);
          alert(err);
        });
    } catch (e) {
      console.log("update MinStakeAmount error:", e);
    }
  }

  const updateMaxStakeAmount = async (e) => {
    e.preventDefault();
    try {
      if (!library) return;
      const web3 = new Web3(library);
      const contract = new web3.eth.Contract(ABI_STAKE, CONTRACT_STAKE);
      await contract.methods
        .updateMaxStakeAmount(convertToReal(maxStakeAmount, 18))
        .send({from: account})
        .then((res)=> {
          setHashInfo(res.transactionHash);
          alert('Done');
        }).catch(err=> {
          console.log("updateMaxStakeAmount method error:", err);
          alert(err);
        });
    } catch (e) {
      console.log("update MaxStakeAmount error:", e);
    }
  }

  const updateToken2CoinSwapPercent = async (e) => {
    e.preventDefault();
    try {
      if (!library) return;
      const web3 = new Web3(library);
      const contract = new web3.eth.Contract(ABI_STAKE, CONTRACT_STAKE);
      await contract.methods
        .updateToken2CoinSwapPercent(convertToReal(token2CoinSwapPercent, 4))
        .send({from: account})
        .then((res)=> {
          setHashInfo(res.transactionHash);
          alert('Done');
        }).catch(err=> {
          console.log("updateToken2CoinSwapPercent method error:", err);
          alert(err);
        });
    } catch (e) {
      console.log("update Token2CoinSwapPercent error:", e);
    }
  }

  const updateSwapPercentExtra = async (e) => {
    e.preventDefault();
    try {
      if (!library) return;
      const web3 = new Web3(library);
      const contract = new web3.eth.Contract(ABI_TRADE, CONTRACT_TRADE);
      await contract.methods
        .updateSwapPercent(convertToReal(swapPercentExtra, 4))
        .send({from: account})
        .then((res)=> {
          setHashInfo(res.transactionHash);
          alert('Done');
        }).catch(err=> {
          console.log("updateSwapPercentExtra method error:", err);
          alert(err);
        });
    } catch (e) {
      console.log("update updateSwapPercent extra error:", e);
    }
  }

  const updateCloseStakePenaltyPercent = async (e) => {
    e.preventDefault();
    try {
      if (!library) return;
      const web3 = new Web3(library);
      const contract = new web3.eth.Contract(ABI_STAKE, CONTRACT_STAKE);
      await contract.methods
        .updateCloseStakePenaltyPercent(convertToReal(closeStakePenaltyPercent, 4))
        .send({from: account})
        .then((res)=> {
          setHashInfo(res.transactionHash);
          alert('Done');
        }).catch(err=> {
          console.log("updateCloseStakePenaltyPercent method error:", err);
          alert(err);
        });
    } catch (e) {
      console.log("update CloseStakePenaltyPercent error:", e);
    }
  }

  const updateMaxPriceUSDTExtra = async (e) => {
    e.preventDefault();
    try {
      if (!library) return;
      const web3 = new Web3(library);
      const contract = new web3.eth.Contract(ABI_TRADE, CONTRACT_TRADE);
      await contract.methods
        .updateMaxPriceUSDT(convertToReal(maxPriceUSDTExtra, 18))
        .send({from: account})
        .then((res)=> {
          setHashInfo(res.transactionHash);
          alert('Done');
        }).catch(err=> {
          console.log("updateMaxPriceUSDTExtra method error:", err);
          alert(err);
        });
    } catch (e) {
      console.log("update updateMaxPriceUSDT extra error:", e);
    }
  }

  const withdrawTokens = async (e) => {
    e.preventDefault();
    try {
      if (!library) return;
      const web3 = new Web3(library);
      const contract = new web3.eth.Contract(ABI_STAKE, CONTRACT_STAKE);
      await contract.methods
        .withdrawTokens(convertToReal(withdrawTokensAmount, 18))
        .send({from: account})
        .then((res)=> {
          setHashInfo(res.transactionHash);
          alert('Done');
        }).catch(err=> {
          console.log("withdrawTokens method error:", err);
          alert(err);
        });
    } catch (e) {
      console.log("withdraw tokens error:", e);
    }
  }

  const withdrawTokensExtra = async (e) => {
    e.preventDefault();
    try {
      if (!library) return;
      const web3 = new Web3(library);
      const contract = new web3.eth.Contract(ABI_TRADE, CONTRACT_TRADE);
      await contract.methods
        .withdrawTokens(convertToReal(withdrawTokensAmountExtra, 18))
        .send({from: account})
        .then((res)=> {
          setHashInfo(res.transactionHash);
          alert('Done');
        }).catch(err=> {
          console.log("withdrawTokensExtra method error:", err);
          alert(err);
        });
    } catch (e) {
      console.log("withdraw tokens extra error:", e);
    }
  }

  const withdrawCoins = async (e) => {
    e.preventDefault();
    try {
      if (!library) return;
      const web3 = new Web3(library);
      const contract = new web3.eth.Contract(ABI_STAKE, CONTRACT_STAKE);
      await contract.methods
        .withdrawCoins(convertToReal(withdrawCoinsAmount, 18))
        .send({from: account})
        .then((res)=> {
          setHashInfo(res.transactionHash);
          alert('Done');
        }).catch(err=> {
          console.log("withdrawCoins method error:", err);
          alert(err);
        });
    } catch (e) {
      console.log("withdraw coins error:", e);
    }
  }

  const withdrawCoinsExtra = async (e) => {
    e.preventDefault();
    try {
      if (!library) return;
      const web3 = new Web3(library);
      const contract = new web3.eth.Contract(ABI_TRADE, CONTRACT_TRADE);
      await contract.methods
        .withdrawCoins(convertToReal(withdrawCoinsAmountExtra, 18))
        .send({from: account})
        .then((res)=> {
          setHashInfo(res.transactionHash);
          alert('Done');
        }).catch(err=> {
          console.log("withdrawCoinsExtra method error:", err);
          alert(err);
        });
    } catch (e) {
      console.log("withdraw coins extra error:", e);
    }
  }

  const loadUser = async () => {
    if (userAddress) {
      try {
        if (!library) return;
        const web3 = new Web3(library);
        const stakeContract = new web3.eth.Contract(ABI_STAKE, CONTRACT_STAKE);
        const tokenContract = new web3.eth.Contract(ABI_TOKEN, CONTRACT_TOKEN);

        tokenContract.methods.balanceOf(userAddress).call()
          .then(res => {
            setUserTokenBalance(convertToVisual(res, 18));
          })
          .catch(err => {
            console.log("load info - balanceOf method error:", err);
            setUserTokenBalance(0);
          });

        web3.eth.getBalance(userAddress)
          .then(res => {
            setUserCoinBalance(convertToVisual(res, 18));
          })
          .catch(err => {
            console.log("load info - getBalance error:", err);
            setUserCoinBalance(0);
          });

        stakeContract.methods.blackList(userAddress).call()
          .then(res => {
            setUserBlackList(res);
          })
          .catch(err => {
            console.log("blackList method error:", err);
            setUserBlackList(false);
          });

        stakeContract.methods.referral2referrer(userAddress).call()
          .then(res => {
            if (res === ZERO_ADDRESS) {
              setReferrer("");
            } else {
              setReferrer(res);
            }
          })
          .catch(err => {
            console.log("referral2referrer method error:", err);
            setReferrer("");
          });

        stakeContract.methods.stakes(userAddress).call()
          .then(res => {
            setUserData({
              amount: convertToVisual(res[0], 18),
              dayPeriod: Number(res[1]),
              percent: convertToVisual(res[2], 4),
              claimHourPeriod: Number(res[3]),
              claimPercent: convertToVisual(res[4], 4),
              reward: convertToVisual(res[5], 18),
              totalReward: convertToVisual(res[6], 18),
              startTime: Number(res[7]),
              updateTime: Number(res[8]),
            });
          })
          .catch(err => {
            console.log("stakes method error:", err);
            setUserTokenBalance(0);
            setUserCoinBalance(0);
            setUserBlackList(false);
            setUserData({
              amount: 0,
              dayPeriod: 0,
              percent: 0,
              claimHourPeriod: 0,
              claimPercent: 0,
              reward: 0,
              totalReward: 0,
              startTime: 0,
              updateTime: 0,
            });
          });
      } catch (e) {
        console.log("load user info error:", e);
      }
    }
  }

  const toggleBlackList = async (e) => {
    e.preventDefault();
    try {
      if (!library) return;
      const web3 = new Web3(library);
      const contract = new web3.eth.Contract(ABI_STAKE, CONTRACT_STAKE);
      await contract.methods
        .toggleBlackList(userAddress)
        .send({from: account})
        .then((res)=> {
          setHashUserInfo(res.transactionHash);
          alert('Done');
        }).catch(err=> {
          console.log("toggleBlackList method error:", err);
          alert(err);
        });
    } catch (e) {
      console.log("toggle black list error:", e);
    }
  }

  const updateReferrer = async (e) => {
    e.preventDefault();
    try {
      if (!library) return;
      const web3 = new Web3(library);
      const contract = new web3.eth.Contract(ABI_STAKE, CONTRACT_STAKE);
      await contract.methods
        .updateReferral2Referrer(userAddress, referrer)
        .send({from: account})
        .then((res)=> {
          setHashInfo(res.transactionHash);
          alert('Done');
        }).catch(err=> {
          console.log("updateReferral2Referrer method error:", err);
          alert(err);
        });
    } catch (e) {
      console.log("update referral to referrer error:", e);
    }
  }

  const updateStake = async (e) => {
    e.preventDefault();
    try {
      if (!library) return;
      const web3 = new Web3(library);
      const contract = new web3.eth.Contract(ABI_STAKE, CONTRACT_STAKE);

      await contract.methods
        .updateStake(
          userAddress,
          convertToReal(userData.amount, 18),
          userData.dayPeriod,
          convertToReal(userData.percent, 4),
          userData.claimHourPeriod,
          convertToReal(userData.claimPercent, 4),
          convertToReal(userData.reward, 18),
          userData.startTime,
          userData.updateTime
        )
        .send({from: account})
        .then((res)=> {
          setHashUserInfo(res.transactionHash);
          alert('Done');
        }).catch(err=> {
          console.log("updateStake method error:", err);
          alert(err);
        });
    } catch (e) {
      console.log("update stake error:", e);
    }
  }

  useEffect(() => {
    try {
      if (!library) return;
      const web3 = new Web3(library);
      const contract = new web3.eth.Contract(ABI_STAKE, CONTRACT_STAKE);
      contract.methods.owner().call()
        .then(res=> {
          if (res === account) {
            setAccess(true);
          } else {
            setAccess(false);
          }
        })
        .catch(err=> {
          console.log("owner method error:", err);
          setAccess(false);
        });
    } catch (e) {
      setAccess(false);
      console.log("access checking error:", e);
    }
  }, [active, account, library]);

  useEffect(() => {
    if (access) {
      loadInfo();
    }
  }, [access, hashUserInfo, hashInfo]);

  useEffect(() => {
    if (access) {
      loadUser();
    }
  }, [access, hashUserInfo]);

  return (
    <Container>
      {!active ? (
        <Connect />
      ) : (!access ? (
          <Refresh text="No access" />
        ) : (
          <>
            <h1>Admin panel</h1>
            <Row className={'mt-5'}>
              <Col>
                <Card>
                  <CardHeader>
                    Admin address
                  </CardHeader>
                  <CardBody>
                    {account}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className={'mt-5'}>
              <Col>
                <Card>
                  <CardHeader>
                    Total information
                  </CardHeader>
                  <CardFooter>
                    <Row>
                      <Col>
                        <strong>Current stake on contract</strong><br />{totalNowStake}
                      </Col>
                      <Col>
                        <strong>Users count</strong><br />{usersCount}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <strong>Reward</strong><br />{totalReward}
                      </Col>
                      <Col>
                        <strong>Referral reward</strong><br />{referralTotalReward}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <strong>Balance</strong><br />{tokenBalance}
                      </Col>
                      <Col>
                        <strong>BNB balance</strong><br />{coinBalance}
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
            <Row className={'mt-5'}>
              <Col>
                <Card>
                  <CardHeader>
                    Basic actions
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col>
                        <strong>{isRun ? "Stop" : "Start"} contract</strong><br />
                        <Button color="danger" onClick={(e)=>updateIsRun(e)}>{isRun ? "Stop" : "Start"}</Button>
                      </Col>
                    </Row>
                    <Form>
                      <FormGroup>
                        <Label><strong>Withdraw AIST</strong></Label>
                        <Input type={"text"} id={"withdraw-tokens"} value={withdrawTokensAmount} onChange={e=>setWithdrawTokensAmount(e.target.value)}/>
                        <Button color={"success"} onClick={e=>withdrawTokens(e)}>Withdraw</Button>
                      </FormGroup>
                    </Form>
                    <Form>
                      <FormGroup>
                        <Label><strong>Withdraw BNB</strong></Label>
                        <Input type={"text"} id={"withdraw-coins"} value={withdrawCoinsAmount} onChange={e=>setWithdrawCoinsAmount(e.target.value)}/>
                        <Button color={"success"} onClick={e=>withdrawCoins(e)}>Withdraw</Button>
                      </FormGroup>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className={'mt-5'}>
              <Col>
                <Card>
                  <CardHeader>
                    Basic actions (extra)
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col>
                        <strong>{isRunExtra ? "Stop" : "Start"} contract</strong><br />
                        <Button color="danger" onClick={(e)=>updateIsRunExtra(e)}>{isRunExtra ? "Stop" : "Start"}</Button>
                      </Col>
                    </Row>
                    <Form>
                      <FormGroup>
                        <Label><strong>Withdraw AIST</strong></Label>
                        <Input type={"text"} id={"withdraw-tokens-extra"} value={withdrawTokensAmountExtra} onChange={e=>setWithdrawTokensAmountExtra(e.target.value)}/>
                        <Button color={"success"} onClick={e=>withdrawTokensExtra(e)}>Withdraw</Button>
                      </FormGroup>
                    </Form>
                    <Form>
                      <FormGroup>
                        <Label><strong>Withdraw BNB</strong></Label>
                        <Input type={"text"} id={"withdraw-coins-extra"} value={withdrawCoinsAmountExtra} onChange={e=>setWithdrawCoinsAmountExtra(e.target.value)}/>
                        <Button color={"success"} onClick={e=>withdrawCoinsExtra(e)}>Withdraw</Button>
                      </FormGroup>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className={'mt-5'}>
              <Col>
                <Card>
                  <CardHeader>
                    User info
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <FormGroup>
                        <Label><strong>Address</strong></Label>
                        <Input type={'text'} id={'address-check'} value={userAddress} onChange={e=>setUserAddress(e.target.value)}/>
                      </FormGroup>
                      <Button color={"success"} onClick={e=>loadUser(e)}>Load</Button>
                    </Form>
                    <Row>
                      <Col>
                        <strong>Balance</strong><br />{userTokenBalance}
                      </Col>
                      <Col>
                        <strong>BNB balance</strong><br />{userCoinBalance}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <strong>Black list</strong><br />{userBlackList + " "}<br />
                        <Button color={"success"} onClick={e=>toggleBlackList(e)}>Toggle</Button>
                      </Col>
                    </Row>
                    <Form>
                      <FormGroup>
                        <Label><strong>Referrer</strong></Label>
                        <Input type={'text'} id={'user-referrer'} value={referrer} onChange={e=>setReferrer(e.target.value)} />
                        <Button color={"success"} onClick={e=>updateReferrer(e)}>Update</Button>
                      </FormGroup>
                      <FormGroup>
                        <Label><strong>Amount</strong></Label>
                        <Input type={'text'} id={'user-amount'} value={userData.amount} onChange={e=>setUserData(prevState => ({...prevState, amount: e.target.value }))} />
                      </FormGroup>
                      <FormGroup>
                        <Label><strong>Day period</strong></Label>
                        <Input type={'text'} id={'user-day-period'} value={userData.dayPeriod} onChange={e=>setUserData(prevState => ({...prevState, dayPeriod: e.target.value }))} />
                      </FormGroup>
                      <FormGroup>
                        <Label><strong>Percent</strong></Label>
                        <Input type={'text'} id={'user-percent'} value={userData.percent} onChange={e=>setUserData(prevState => ({...prevState, percent: e.target.value }))} />
                      </FormGroup>
                      <FormGroup>
                        <Label><strong>Claim hour period</strong></Label>
                        <Input type={'text'} id={'user-claim-hour-period'} value={userData.claimHourPeriod} onChange={e=>setUserData(prevState => ({...prevState, claimHourPeriod: e.target.value }))} />
                      </FormGroup>
                      <FormGroup>
                        <Label><strong>Claim percent</strong></Label>
                        <Input type={'text'} id={'user-claim-percent'} value={userData.claimPercent} onChange={e=>setUserData(prevState => ({...prevState, claimPercent: e.target.value }))} />
                      </FormGroup>
                      <FormGroup>
                        <Label><strong>Reward</strong></Label>
                        <Input type={'text'} id={'user-reward'} value={userData.reward} onChange={e=>setUserData(prevState => ({...prevState, reward: e.target.value }))} />
                      </FormGroup>
                      <FormGroup>
                        <Label><strong>Total reward</strong></Label>
                        <Input type={'text'} id={'user-total-reward'} value={userData.totalReward} disabled />
                      </FormGroup>
                      <FormGroup>
                        <Label><strong>Start time</strong></Label>
                        <Input type={'text'} id={'user-start-time'} value={userData.startTime} onChange={e=>setUserData(prevState => ({...prevState, startTime: e.target.value }))} />
                      </FormGroup>
                      <FormGroup>
                        <Label><strong>Update time</strong></Label>
                        <Input type={'text'} id={'user-update-time'} value={userData.updateTime} onChange={e=>setUserData(prevState => ({...prevState, updateTime: e.target.value }))} />
                      </FormGroup>
                      <Button color={"success"} onClick={e=>updateStake(e)}>Update</Button>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className={'mt-5'}>
              <Col>
                <Card>
                  <CardHeader>
                    Сontract parameters
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <FormGroup>
                        <Label><strong>Fee</strong></Label>
                        <Input type={'text'} id={'contract-fee'} value={fee} onChange={e=>setFee(e.target.value)} />
                        <Button color={"success"} onClick={e=>updateFee(e)}>Update</Button>
                      </FormGroup>
                    </Form>
                    <Form>
                      <FormGroup>
                        <Label><strong>Slippage (%)</strong></Label>
                        <Input type={'text'} id={'contract-slippage'} value={slippage} onChange={e=>setSlippage(e.target.value)} />
                        <Button color={"success"} onClick={e=>updateSlippage(e)}>Update</Button>
                      </FormGroup>
                    </Form>
                    <Form>
                      <FormGroup>
                        <Label><strong>Min amount of stake</strong></Label>
                        <Input type={'text'} id={'contract-min-stake-amount'} value={minStakeAmount} onChange={e=>setMinStakeAmount(e.target.value)} />
                        <Button color={"success"} onClick={e=>updateMinStakeAmount(e)}>Update</Button>
                      </FormGroup>
                    </Form>
                    <Form>
                      <FormGroup>
                        <Label><strong>Max amount of stake</strong></Label>
                        <Input type={'text'} id={'contract-max-stake-amount'} value={maxStakeAmount} onChange={e=>setMaxStakeAmount(e.target.value)} />
                        <Button color={"success"} onClick={e=>updateMaxStakeAmount(e)}>Update</Button>
                      </FormGroup>
                    </Form>
                    <Form>
                      <FormGroup>
                        <Label><strong>Swap percent for exchange</strong></Label>
                        <Input type={'text'} id={'contract-token2coin-swap-percent'} value={token2CoinSwapPercent} onChange={e=>setToken2CoinSwapPercent(e.target.value)} />
                        <Button color={"success"} onClick={e=>updateToken2CoinSwapPercent(e)}>Update</Button>
                      </FormGroup>
                    </Form>
                    <Form>
                      <FormGroup>
                        <Label><strong>Max USDT price </strong></Label>
                        <Input type={'text'} id={'contract-close-stake-penalty-percent'} value={closeStakePenaltyPercent} onChange={e=>setCloseStakePenaltyPercent(e.target.value)} />
                        <Button color={"success"} onClick={e=>updateCloseStakePenaltyPercent(e)}>Update</Button>
                      </FormGroup>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className={'mt-5'}>
              <Col>
                <Card>
                  <CardHeader>
                    Сontract parameters (extra)
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <FormGroup>
                        <Label><strong>Slippage (%)</strong></Label>
                        <Input type={'text'} id={'contract-slippage-extra'} value={slippageExtra} onChange={e=>setSlippageExtra(e.target.value)} />
                        <Button color={"success"} onClick={e=>updateSlippageExtra(e)}>Update</Button>
                      </FormGroup>
                    </Form>
                    <Form>
                      <FormGroup>
                        <Label><strong>Swap percent for exchange</strong></Label>
                        <Input type={'text'} id={'contract-swap-percent-extra'} value={swapPercentExtra} onChange={e=>setSwapPercentExtra(e.target.value)} />
                        <Button color={"success"} onClick={e=>updateSwapPercentExtra(e)}>Update</Button>
                      </FormGroup>
                    </Form>
                    <Form>
                      <FormGroup>
                        <Label><strong>Max USDT price </strong></Label>
                        <Input type={'text'} id={'max-usdt-price-extra'} value={maxPriceUSDTExtra} onChange={e=>setMaxPriceUSDTExtra(e.target.value)} />
                        <Button color={"success"} onClick={e=>updateMaxPriceUSDTExtra(e)}>Update</Button>
                      </FormGroup>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )
      )}
    </Container>
  )
}

export default AdminPage;