import React from "react";
import {Button, Col, Row} from "reactstrap";

const Refresh = ({text}) => {
  return (
    <>
      <Row className={'mt-5'}>
        <Col className={'text-center'}>
          <Button><a href="/">{text}</a></Button>
        </Col>
      </Row>
    </>
  )
}

export default Refresh;