import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import AdminPage from "./AdminPage";

import { Web3ReactProvider } from '@web3-react/core'
import Web3 from "web3";

function getLibrary(provider) {
  const library = new Web3(provider);
  library.pollingInterval = 12000;
  return library;
}

function App() {
  return (
    <div className="App">
      <Web3ReactProvider getLibrary={getLibrary}>
        <AdminPage />
      </Web3ReactProvider>
    </div>
  );
}

export default App;
